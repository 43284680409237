export function genLang(text) {
  const lang = localStorage.getItem("i18nextLng") ?? 'fr';
  try {
    if (typeof text === "object") {
      return text[lang];
    }
  } catch (e) {
    console.error(e);
    console.error("TRAD KEY NOT FOUND: ", text);
  }
}
